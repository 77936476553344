.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Radio-group-legend{
  position: relative;
  top: 9px;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{
  margin: 0;
  padding: 0;
  min-height: 100%;
  position: relative;
  text-align: center;
}

.footer {
  bottom: 0;
  width: 100%;
  height: 40px;
  background-color: #222222;
}

.label{
  text-align: right;
  background-color: #EEEEEE;
}

.field{
  text-align: left;
}

table{
  border-style: solid;
  border-width: 1px;
  border-color: #DDDDDD;
}


.bg_img{
  text-align: center;
}

div > h1{
  text-align: center;
}

div > form > h3{
  text-align: center;
}
div > form > div{
  text-align: center;
}

.login_container{
  text-align: center;
}
.order_page{
  text-align: center;
}


div > h4{
  text-align: center;
  color:#007BFF;
}

.Must-fill-indicator{
  color: #FF0000;
  position: relative;
}

#gayj_red_text{
  color:#FF0000;
  font-weight: bold;
}